

import { ERROR_CODES } from '@yggdrasil/Checkout/V2/Services/checkout-critical-errors'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutConfigurationErrorV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  errorMessageMapping = {
    [ERROR_CODES.EMPTY_PAYMENT_METHODS_ERROR]: 'Set Up Payments.ai to Start Collecting Orders',
    [ERROR_CODES.EMPTY_PRODUCTS_ERROR]: 'There are no products configured.',
    [ERROR_CODES.ORDER_RECURRING_INTERVAL_MISMATCH]: 'Orders can only have one recurring interval'
  }

  mount() {
    const prettifyErrorMessage = (error) => {
      if (this.errorMessageMapping[error.code]) return this.errorMessageMapping[error.code]

      if (error.fetchErrorType == CFFetcherErrorTypes.NETWORK_ERROR) {
       return `A critical network error is preventing checkout from continuing. 
Please check your connection and try again after reloading the page. If the problem persists, 
please contact support. Error code: ${error.code}`  
      } else {
        // TODO: When we have bugsnag support, change this error message to:
        // Add A critical server error occurred during checkout. Tech support has been notified and will investigate. Please retry later, and/or contact support at ...
        return `A critical server error occurred during checkout. Please retry later, and/or contact support. Error code: ${error.code}`
      }
    }

    Checkout.store.criticalErrors.subscribe((errors) => {
      this.errors = errors.map(prettifyErrorMessage);;
      this.render()
    });

    this.errors = Checkout.store.criticalErrors.get().map(prettifyErrorMessage);
    this.render()
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const errors = this.errors ?? [] ;

      
      let html = '';
      {
        html += `<div class="elCheckoutConfigError`
        if (errors.length == 0) {
          html += ` elHide`
        }
        html += `">`
        const c0 = errors
        const fl1 = new CF2ForloopDrop(c0.length)
        for (const error of c0) {
          const forloop = fl1
          html += `<span>${error}</span>`
          forloop.next();
        }
        html += `</div>`

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["CheckoutConfigurationErrorV1"] = CheckoutConfigurationErrorV1



 import  { computed } from "nanostores"

import "./radio-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutShippingProfileSelectV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.$loading = computed(Checkout.store.loadingShipping, (loadingShipping) => loadingShipping)
    this.$summaryHasError = computed(Checkout.store.summary, summary => summary.state === 'error')
    const renderDeps = computed([
      Checkout.store.shippingOptions, 
      Checkout.computed.hasValidShippingAddress,
      this.$loading,
      this.$summaryHasError
    ], (...args) => args)
    renderDeps.listen(([shippingOptions, hasValidShippingAddress, loadingShipping, summaryHasError]) => {
      this.summaryHasError = summaryHasError
      this.hasValidShippingAddress = hasValidShippingAddress
      this.loadingShipping = loadingShipping
      this.shippingOption = Checkout.store.shippingOption.get()
      this.shippingOptions = hasValidShippingAddress && !loadingShipping && shippingOptions && shippingOptions.map((option) => { 
        const { description, amount: { amount, currency }, amount_formatted } = option
        return ({
          key: JSON.stringify(option),
          value: option,
          description, 
          priceLabel: amount_formatted,
        })
      }) 
      this.update()
    })
    Checkout.store.shippingOption.listen(shippingOption => {

      this.setRadio(shippingOption)
    })  
    this.update()

    Checkout.computed.isNewDigitalWalletPayment.subscribe(isNewDigitalWalletPayment => {
      if (isNewDigitalWalletPayment) {
        this.hide()
      } else {
        this.show()
      }
    })
  }

  show() {
    this.element.classList.remove('elHide')
  }
  hide() {
    this.element.classList.add('elHide')
  }
  
  update () {
    this.render()
    this.bindElements()
  }

  bindElements () {
    const shippingProfileCards = this.element.querySelectorAll('.elShippingProfile');
    this.setRadio(Checkout.store.shippingOption.get())
    shippingProfileCards.forEach(card => {
      const key = card.getAttribute('data-shipping-option')
      if (key){
        card.addEventListener('click', ev => {
          ev.preventDefault();
          const option = this.shippingOptions.find(({ description }) => description === key)
          Checkout.store.shippingOption.set(option.value)
        })
      }
    })
    const emptyCardIcons = this.element.querySelectorAll('.elShippingProfilesEmpty .fa')
    emptyCardIcons.forEach(el => {
      const iconClass = el.className.split(' ')[1]      
      this.$loading.subscribe(loading => {
        el.className = (loading ? 'fa fa-spinner fa-spin': `fa ${iconClass}`)        
      })
    })
  }
  setRadio (shippingOption) {
    const radio = shippingOption && this.element.querySelector(`[data-shipping-option="${shippingOption.description}"] .elRadioInput`)
    if (radio) {
      radio.checked = true
    }
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const title = this.title ?? null ;
const titleEnabled = this.titleEnabled ?? null ;
const otherTexts = this.otherTexts ?? null ;
const shippingOptions = this.shippingOptions ?? [] ;
const shippingOption = this.shippingOption ?? undefined ;
const hasValidShippingAddress = this.hasValidShippingAddress ?? false ;
const loadingShipping = this.loadingShipping ?? false ;
const summaryHasError = this.summaryHasError ?? false ;

      
      let html = '';
      {
        if (titleEnabled) {
          html += `<div class="elCheckoutFormLabelContainer"><div class="elCheckoutFormLabel">${title}</div> <hr class="elCheckoutFormLabelDivider"/></div>`
        }
        html += `<div class="elShippingProfileList">`
        if (summaryHasError) {
          html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><p>Something Unexpected Happened!</p></div></div></div>`
        } else if (hasValidShippingAddress || true != true) {
          if (loadingShipping) {
            html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><div><i class="fa fa-truck"></i></div></div></div></div>`
          } else if (shippingOptions && shippingOptions.length > 0) {
            const c0 = shippingOptions
            const fl1 = new CF2ForloopDrop(c0.length)
            for (const shippingProfile of c0) {
              const forloop = fl1
              const currentIndex = forloop.index0;
              const isDefaultValue = currentIndex == 0;
              const hasValue = shippingOption;
              const isSelected = shippingOption == shippingProfile.key;
              html += `      `
              const isChecked = hasValue && isSelected || isDefaultValue;
              html += `      `
              html += `<div class="elShippingProfileCardWrapper"><div class="elShippingProfile elShippingProfileLayout`
              if (isChecked) {
                html += ` elShippingProfileSelected`
              }
              html += ` elShippingProfileClickable" data-shipping-option="${shippingProfile.description}"><div class="elShippingProfileLayoutBackground"></div><div class="elShippingProfileSelectInput">`
              if (isChecked) {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="shipping_profile" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              } else {
                html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="" name="shipping_profile"  class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div>`
              }
              html += `</div>  <div class="elShippingProfileCardNameContainer"><div class="elShippingProfileCardInfoName">${shippingProfile.description}</div></div>    <div class="elShippingProfileCardPriceContainer"><span class="elShippingProfileInfoPrice elShippingProfileFinalPrice">${shippingProfile.priceLabel}</span></div></div></div>`
              forloop.next();
            }
          } else if (shippingOptions && shippingOptions.length == 0) {
            html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><div><i class="fa fa-truck"></i></div><p>Shipping is not available for this location.</p></div></div></div>`
          }
        } else {
          html += `<div class="elShippingProfileCardWrapper elShippingProfilesEmpty">      <div class="elShippingProfile elShippingProfileLayout"><div class="elShippingProfileLayoutBackground"></div><div><div><i class="fa fa-truck"></i></div><p>${otherTexts.shippingOptionsPlaceholder}</p></div></div></div>`
        }
        html += `</div>`

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["CheckoutShippingProfileSelectV1"] = CheckoutShippingProfileSelectV1


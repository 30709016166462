

import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutLoadingV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    Checkout.store.submitting.listen((submitting) => {
      const state = submitting.state
      switch(state) {
        case Checkout.SubmittingStates.IDLE: {
          this.hide()
          break;
        }
        case Checkout.SubmittingStates.START: {
          this.show()
          break;
        }
        case Checkout.SubmittingStates.WAITING_ON_QUEUE: {
          this.show()
          this.showWaitingOnQueueText(`Waiting on queue (Retrying in ${submitting.remainingSeconds}s)`)
          break;
        }
        case Checkout.SubmittingStates.ERROR: {
          this.hide()
          break;
        }
      }
    })
  }
  show() {
    this.element.classList.remove('elHide')
  }
  showWaitingOnQueueText(text) {
    this.element.querySelector('.elCheckoutLoadingWaitingOnQueue').classList.remove('elHide')
    this.element.querySelector('.elCheckoutLoadingWaitingOnQueue').innerHTML = text
  }
  hideWaitingOnQueueText() {
    this.element.querySelector('.elCheckoutLoadingWaitingOnQueue').classList.add('elHide')
  }
  hide() {
    this.element.classList.add('elHide')
  }



}

window["CheckoutLoadingV1"] = CheckoutLoadingV1

